import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import auth from './auth';
import insights from './insights';
import networks from './networks';
import sites from './sites';
import locations from './locations';
import devices from './devices';
import feeders from './feeders';
import tags from './tags';
import blender from './blender';
import devicetype from './devicetype';
import scenes from './scenes';
import signagevendors from './signagevendors';
import targetingstrategy from './targetingstrategy';

const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  auth,
  insights,
  networks,
  sites,
  locations,
  devices,
  feeders,
  tags,
  blender,
  devicetype,
  // window,
  scenes,
  signagevendors,
  targetingstrategy
});

export default createRootReducer
